export const companyInfo = [
  { label: "GST Number", value: "GSTNumber" },
  { label: "TTL Expiry Date", value: "TTL_ExpiryDate" },
  { label: "Acquired Calendar Task", value: "acquiredCalenderTask" },
  { label: "Acquired Customer Creation", value: "acquiredCustomerCreation" },
  { label: "Acquired Face Attendance", value: "acquiredFaceAttendance" },
  { label: "Acquired Field Tracking", value: "acquiredFieldTracking" },
  { label: "Acquired Leave Policy", value: "acquiredLeavePolicy" },
  { label: "Acquired Payroll", value: "acquiredPayroll" },
  { label: "Acquired Reimbursement", value: "acquiredReimbursement" },
  { label: "Acquired Route Optimization", value: "acquiredRouteOptimization" },
  { label: "Acquired Task OTP Completion", value: "acquiredTaskOtpCompletion" },
  { label: "Acquired Time Sheet Filling", value: "acquiredTimeSheetFilling" },
  { label: "Admin Contact Number", value: "adminContactNumber" },
  { label: "Admin Email", value: "adminEmail" },
  { label: "Allow Company Task", value: "allowCompanyTask" },
  { label: "Allow Work From Home", value: "allowWorkFromHome" },
  {
    label: "Apply Regularization For Month",
    value: "applyRegularizationForMonth",
  },
  { label: "Company Email", value: "companyEmail" },
  { label: "Company Name", value: "companyName" },
  { label: "Created Date", value: "createdDate" },
  { label: "Customer Form Link", value: "customerFormLink" },
  { label: "Daily Report PDF", value: "dailyReportPdf" },
  { label: "Enable PDF Creation", value: "enablePdfCreation" },
  { label: "Expire Calendar Task Plan", value: "expireCalenderTaskPlan" },
  {
    label: "Expire Customer Creation Plan",
    value: "expireCustomerCreationPlan",
  },
  { label: "Expire Face Attendance Plan", value: "expireFaceAttendancePlan" },
  { label: "Expire Field Tracking Plan", value: "expireFieldTrackingPlan" },
  { label: "Expire Leave Policy Plan", value: "expireLeavePolicyPlan" },
  { label: "Expire Payroll Plan", value: "expirePayrollPlan" },
  { label: "Expire Reimbursement Plan", value: "expireReimbursementPlan" },
  {
    label: "Expire Route Optimization Plan",
    value: "expireRouteOptimizationPlan",
  },
  {
    label: "Expire Task OTP Completion Plan",
    value: "expireTaskOtpCompletionPlan",
  },
  {
    label: "Expire Time Sheet Filling Plan",
    value: "expireTimeSheetFillingPlan",
  },
  { label: "Face Biometric", value: "faceBiometric" },
  { label: "Modules", value: "modules" },
  { label: "Monthly Report PDF", value: "monthlyReportPdf" },
  { label: "Number of Employees", value: "numberOfEmployees" },
  { label: "Pending Notification Time", value: "pendingNotificationTime" },
  {
    label: "Pending Notification Timezone",
    value: "pendingNotificationTimezone",
  },
  { label: "Plan Name", value: "planName" },
  { label: "Total Calendar Task", value: "totalCalenderTask" },
  { label: "Total Customer Creation", value: "totalCustomerCreation" },
  { label: "Total Face Attendance", value: "totalFaceAttendance" },
  { label: "Total Field Tracking", value: "totalFieldTracking" },
  { label: "Total Leave Policy", value: "totalLeavePolicy" },
  { label: "Total Payroll", value: "totalPayroll" },
  { label: "Total Reimbursement", value: "totalReimbursement" },
  { label: "Total Route Optimization", value: "totalRouteOptimization" },
  { label: "Total Task OTP Completion", value: "totalTaskOtpCompletion" },
  { label: "Total Time Sheet Filling", value: "totalTimeSheetFilling" },
  { label: "Used Task Count", value: "usedTaskCount" },
  { label: "Validity Upto", value: "validityUpto" },
  { label: "Weekly Report PDF", value: "weeklyReportPdf" },
  { label: "Work From Home Per Month", value: "workFromHomePerMonth" },
];

export const displayCompanyDetails = (companyDetailsState) => {
  return companyInfo.map(({ label, value }) => {
    let displayValue;

    if (
      value === "TTL_ExpiryDate" ||
      value === "createdDate" ||
      value === "expireCalenderTaskPlan" ||
      value === "expireCustomerCreationPlan" ||
      value === "expireFaceAttendancePlan" ||
      value === "expireFieldTrackingPlan" ||
      value === "expireLeavePolicyPlan" ||
      value === "expirePayrollPlan" ||
      value === "expireReimbursementPlan" ||
      value === "expireRouteOptimizationPlan" ||
      value === "expireTaskOtpCompletionPlan" ||
      value === "expireTimeSheetFillingPlan" ||
      value === "validityUpto"
    ) {
      displayValue = companyDetailsState?.[value]
        ? companyDetailsState[value].toDate().toLocaleDateString()
        : "N/A";
    } else {
      displayValue =
        value === "modules"
          ? companyDetailsState?.[value]
            ? JSON.stringify(companyDetailsState[value])
            : "N/A"
          : companyDetailsState?.[value] || "N/A";
    }

    return <p key={value}>{`${label}: ${displayValue}`}</p>;
  });
};
