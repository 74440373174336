import React from "react";
import { useSelector } from "react-redux";
import { Card, ListGroup, Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ActivityLogs = () => {
  const navigate = useNavigate();
  const activityLog = useSelector((state) => state.state.activityLog);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-sm">
            <Card.Header className="bg-primary text-white text-center">
              <div className="d-flex justify-content-between align-items-center">
                <Button variant="light" onClick={() => navigate("/dashboard")}>
                  Back
                </Button>
                <span>Activity Logs</span>
              </div>
            </Card.Header>
            <Card.Body>
              {activityLog && activityLog.length > 0 ? (
                <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                  <ListGroup variant="flush">
                    {activityLog
                      .slice() // Create a shallow copy of the array to avoid mutating the original
                      .sort(
                        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                      )
                      .map((log, index) => (
                        <ListGroup.Item
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span className="text-secondary">{log?.message}</span>
                          <small className="text-muted">Log {index + 1}</small>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </div>
              ) : (
                <p className="text-center text-muted">
                  No activity logs found.
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ActivityLogs;
