// src/hooks/useFetchCompanies.js

import { useState, useEffect } from "react";
import { fetchCompanyList } from "../database/firebaseApis";

export default function useFetchCompanies() {
  const [companyList, setCompanyList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await fetchCompanyList();
        setCompanyList(companies);
      } catch (err) {
        setError(err);
        console.error("Error fetching company list:", err);
      }
    };

    fetchCompanies();
  }, []);

  return { companyList, error };
}
