export const userDetailsInfo = [
  { label: "Battery Level", value: "Batterylevel" },
  { label: "Branch", value: "Branch" },
  { label: "Casual Leaves", value: "CasualLeaves" },
  { label: "Company Status", value: "CompanyStatus" },
  { label: "Department", value: "Department" },
  { label: "Designation", value: "Designation" },
  { label: "Device", value: "Device" },
  { label: "Email", value: "Email" },
  { label: "Facebook URL", value: "FacebookUrl" },
  { label: "GeoFence Attendance", value: "GeoFenceAttendance" },
  { label: "Grace Time", value: "GraceTime" },
  { label: "ID", value: "ID" },
  { label: "Joining Date", value: "JoiningDate" },
  { label: "Last Location", value: "LastLocation" },
  { label: "LinkedIn URL", value: "LinkedinUrl" },
  { label: "Name", value: "Name" },
  { label: "Password", value: "Password" },
  { label: "Phone", value: "Phone" },
  { label: "Reporting Manager", value: "ReportingManager" },
  { label: "Reporting Manager ID", value: "ReportingManagerId" },
  { label: "Role ID", value: "RoleID" },
  { label: "Shift Details", value: "ShiftDetails" },
  { label: "Sick Leaves", value: "SickLeaves" },
  { label: "Status", value: "Status" },
  { label: "User Type", value: "UserType" },
  { label: "WFH", value: "WFH" },
  { label: "WFH Leaves", value: "WFHLeaves" },
  { label: "Active", value: "active" },
  { label: "Aadhar Card", value: "addharCard" },
  { label: "Admin", value: "admin" },
  { label: "Allow Calender Task", value: "allowCalenderTask" },
  { label: "Allow Customer Creation", value: "allowCustomerCreation" },
  { label: "Allow Face Attendance", value: "allowFaceAttendance" },
  { label: "Allow Field Tracking", value: "allowFieldTracking" },
  { label: "Allow Leave Creation", value: "allowLeaveCreation" },
  { label: "Allow Payroll", value: "allowPayroll" },
  { label: "Allow Reimbursement", value: "allowReimbursement" },
  { label: "Allow Route Optimization", value: "allowRouteOptimization" },
  { label: "Allow Task OTP Completion", value: "allowTaskOtpCompletion" },
  { label: "Allow Timesheet Filling", value: "allowTimeSheetFilling" },
  { label: "Branches", value: "branches" },
  { label: "Company ID", value: "companyId" },
  { label: "Country Code", value: "countryCode" },
  { label: "Cross Reporting ID", value: "crossReportingId" },
  { label: "Custom Fields Model", value: "customFieldsModel" },
  { label: "Device ID", value: "deviceId" },
  { label: "Dial Code", value: "dialCode" },
  { label: "Email Verified", value: "emailVerified" },
  { label: "Employee ID", value: "employeeId" },
  { label: "Employment Type", value: "employmentType" },
  { label: "Face Biometric", value: "faceBiometric" },
  { label: "Face Data", value: "faceData" },
  { label: "FCM Token", value: "fcmToken" },
  { label: "Gender", value: "gender" },
  { label: "Homepage Tutorial Mobile", value: "homepageTutorailMobile" },
  { label: "ID", value: "id" },
  { label: "Image URL", value: "imageUrl" },
  { label: "Last Login Time", value: "lastLoginTime" },
  { label: "Late Remark Days", value: "lateRemarkDays" },
  { label: "Leave Structure ID", value: "leaveStructureId" },
  { label: "Leave Structure Name", value: "leaveStructureName" },
  { label: "Lunch Duration", value: "lunchDuration" },
  { label: "Lunch Time", value: "lunchTime" },
  { label: "Notification Check In", value: "notificationCheckIn" },
  { label: "Notification Check Out", value: "notificationCheckOut" },
  { label: "Notification Clock In", value: "notificationClockIn" },
  { label: "Notification Clock Out", value: "notificationClockOut" },
  { label: "Notification Lead", value: "notificationLead" },
  { label: "Notification Leave", value: "notificationLeave" },
  { label: "Notification Regularization", value: "notificationRegularization" },
  { label: "Notification Reimbursement", value: "notificationReimbursement" },
  { label: "PAN Card", value: "panCard" },
  { label: "Person Security ID", value: "personSecurityId" },
  { label: "Salary Calculation Type", value: "salaryCalculationType" },
  { label: "Salary Structure ID", value: "salaryStructureId" },
  { label: "Salary Structure Name", value: "salaryStructureName" },
  { label: "Search Index", value: "searchIndex" },
  { label: "Secret Key", value: "secretKey" },
  { label: "Selected", value: "selected" },
  { label: "Shift Structure ID", value: "shiftStructureId" },
  { label: "Shift Structure Name", value: "shiftStructureName" },
  { label: "Shift Times", value: "shiftTimes" },
  { label: "Time Line Data Filling Type", value: "timeLineDataFillingType" },
  { label: "Tracking Type", value: "trackingType" },
  { label: "UPI ID", value: "upi_Id" },
  { label: "User Hourly Salary", value: "userHourlySalary" },
  { label: "User Salary", value: "userSalary" },
  { label: "Version Code", value: "versionCode" },
  { label: "Web Access", value: "webAccess" },
  { label: "Weekly Schedule", value: "weeklySchedule" },
];
