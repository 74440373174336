import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCompanyReportFields,
  fetchActivityLogsFirebase,
  addLogActivityLogsFirebase,
  addPaymentFirebase,
} from "../database/firebaseApis";

// Existing thunk for fetching company report fields
export const fetchActivityLogs = createAsyncThunk(
  "activityLogs/fetchActivityLogs",
  async () => {
    return fetchActivityLogsFirebase();
  }
);

// Existing thunk for fetching company report fields
export const fetchCompanyReportFieldsSlice = createAsyncThunk(
  "fetchCompanyReportFieldsSlice",
  async (companyID) => {
    return fetchCompanyReportFields(companyID);
  }
);

// Thunk to log activity
export const logActivityThunk = createAsyncThunk(
  "activityLogs/logActivity",
  async (logStatement) => {
    const logData = {
      message: logStatement,
      timestamp: new Date().toISOString(), // Use ISO string for consistency
    };
    console.log(" Hi I am LogData", logData);
    return addLogActivityLogsFirebase(logData); // Return the log data to update Redux state
  }
);

// Thunk to log activity
export const addPayment = createAsyncThunk(
  "addPayment",
  async (paymentInfo) => {
    const logData = {
      message: paymentInfo,
      timestamp: new Date().toISOString(), // Use ISO string for consistency
    };
    console.log(" Hi I am LogData", logData);
    return addPaymentFirebase(logData); // Return the log data to update Redux state
  }
);

// New thunk for fetching IP and geolocation details
export const fetchGeoLocation = createAsyncThunk(
  "geo/fetchGeoLocation",
  async () => {
    const response = await fetch(
      "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
    );
    const data = await response.json();
    console.log("Hii am data from geolocation", data);
    return data; // Return fetched geolocation data (including IP)
  }
);

// Single slice managing both company report fields and geolocation
const stateSlice = createSlice({
  name: "state",

  initialState: {
    admin: null,
    companyReportFields: { daily: false, weekly: false, monthly: false },
    faceBiometric: false,
    status: "",
    error: "",
    ip: null, // Store for IP
    locationDetails: null, // Store for geolocation details
    activityLog: [],
  },
  reducers: {
    setActivityLog(state, action) {
      state.activityLog = [...state.activityLog, action.payload];

      console.log("why are we vanishing", state.activityLog);
    },
    setAdmin(state, action) {
      state.admin = action.payload;

      console.log("why are we vanishing", state.activityLog);
    },
  },
  extraReducers(builder) {
    builder
      // Handle fetchCompanyReportFieldsSlice
      .addCase(fetchCompanyReportFieldsSlice.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCompanyReportFieldsSlice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companyReportFields = action.payload;
      })
      .addCase(fetchCompanyReportFieldsSlice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Handle fetchGeoLocation
      .addCase(fetchGeoLocation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGeoLocation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ip = action.payload.IPv4;
        state.locationDetails = action.payload;
      })
      .addCase(fetchGeoLocation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Handle fetching activity logs
      .addCase(fetchActivityLogs.pending, (state) => {
        state.status = "loading"; // Optionally, set a loading status
      })
      .addCase(fetchActivityLogs.fulfilled, (state, action) => {
        console.log("action.payLoad in redux", action.payload);
        state.status = "succeeded";
        state.activityLog = action.payload; // Populate activityLog with fetched data
      })
      .addCase(fetchActivityLogs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message; // Store error message if fetching fails
      })
      // Handle logging activity
      .addCase(logActivityThunk.pending, (state) => {
        state.status = "logging"; // Optional: Set a logging status
      })
      .addCase(logActivityThunk.fulfilled, (state, action) => {
        state.activityLog = [...state.activityLog, action.payload]; // Add new log to activityLog
        console.log("Activity logged:", action.payload);
        state.status = "succeeded"; // Update status
      })
      .addCase(logActivityThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message; // Store error message if logging fails
      });
  },
});
export const { setActivityLog, setAdmin } = stateSlice.actions;
export default stateSlice.reducer;
