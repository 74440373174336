import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useFetchCompanies from "../hooks/useFetchComponies";
import { useDispatch, useSelector } from "react-redux";
import { addPayment } from "../redux/stateSlice";
import { expiryFiltersPayments } from "../database/expiryFilters";

function PaymentForm({ show, onHide }) {
  const dispatch = useDispatch();
  const { companyList } = useFetchCompanies(); // Fetch the company list
  const admin = useSelector((state) => state.auth.logedAdmin);

  // Use a single state object to handle all form fields
  const [formData, setFormData] = useState({
    paymentType: "",
    companyName: "",
    amount: "",
    paymentMode: "",
    numberOfUsers: "",
    expiryFilters: [],
    duration: "", // New field for duration
  });

  // Set company filters
  const [companyFilters, setCompanyFilters] = useState([]);

  // Handle input changes dynamically
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle expiry filters multi-selection
  const handleFilterChange = (key) => {
    setCompanyFilters((prevFilters) =>
      prevFilters.includes(key)
        ? prevFilters.filter((filter) => filter !== key)
        : [...prevFilters, key]
    );
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation for required fields
    const {
      paymentType,
      companyName,
      amount,
      paymentMode,
      numberOfUsers,
      duration,
    } = formData;

    if (!paymentType) {
      alert("Please select a payment type.");
      return;
    }

    if (!companyName) {
      alert("Please select a company.");
      return;
    }

    if (!amount || amount <= 0) {
      alert("Please enter a valid amount.");
      return;
    }

    if (!paymentMode) {
      alert("Please select a payment mode.");
      return;
    }

    if (!numberOfUsers || numberOfUsers <= 0) {
      alert("Please enter a valid number of users.");
      return;
    }

    if (!duration) {
      alert("Please select a plan duration.");
      return;
    }

    if (companyFilters.length === 0) {
      alert("Please select at least one expiry filter.");
      return;
    }

    // If validation passes, dispatch the data
    const submissionData = {
      ...formData,
      expiryFilters: companyFilters,
    };
    dispatch(addPayment(submissionData));

    console.log("Form Submitted: ", submissionData);
    alert("Form Submitted!");

    // Close the modal after submission
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Payment Information
        </Modal.Title>
        {/* Display admin email in the corner */}
        <div style={{ position: "absolute", right: "20px", top: "10px" }}>
          <small>Logged In: {admin?.email}</small>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Payment Type Dropdown */}
          <Form.Group controlId="formPaymentType">
            <Form.Label>Payment Type</Form.Label>
            <Form.Control
              as="select"
              name="paymentType"
              value={formData.paymentType}
              onChange={handleInputChange}
            >
              <option value="">Select Payment Type</option>
              <option value="Renew">Renew</option>
              <option value="Signup">Signup</option>
              <option value="Refund">Refund</option>
            </Form.Control>
          </Form.Group>

          {/* Company Name */}
          <Form.Group controlId="formCompanyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              as="select"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
            >
              <option value="">Select a Company</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.id}>
                  ({company.id})
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Amount */}
          <Form.Group controlId="formAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Payment Mode */}
          <Form.Group controlId="formPaymentMode">
            <Form.Label>Payment Mode</Form.Label>
            <Form.Control
              as="select"
              name="paymentMode"
              value={formData.paymentMode}
              onChange={handleInputChange}
            >
              <option value="">Select Payment Mode</option>
              <option>Credit Card</option>
              <option>Debit Card</option>
              <option>Net Banking</option>
              <option>UPI</option>
              <option>Cash</option>
            </Form.Control>
          </Form.Group>

          {/* Number of Users */}
          <Form.Group controlId="formNumberOfUsers">
            <Form.Label>Number of Users</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter number of users"
              name="numberOfUsers"
              value={formData.numberOfUsers}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Duration Dropdown */}
          <Form.Group controlId="formDuration">
            <Form.Label>Plan Duration</Form.Label>
            <Form.Control
              as="select"
              name="duration"
              value={formData.duration}
              onChange={handleInputChange}
            >
              <option value="">Select Duration</option>
              <option value="30">30 Days</option>
              <option value="60">60 Days</option>
              <option value="90">90 Days</option>
            </Form.Control>
          </Form.Group>

          {/* Expiry Filters Multi-Selection */}
          <Form.Group controlId="formExpiryFilters">
            <Form.Label>Expiry Filters</Form.Label>
            {/* Scrollable container */}
            <div style={{ maxHeight: "150px", overflowY: "auto" }}>
              {expiryFiltersPayments.map((filter) => (
                <Row className="align-items-center mb-2" key={filter.key}>
                  <Col xs="auto" className="pr-2">
                    <Form.Check
                      onChange={() => handleFilterChange(filter.key)}
                      checked={companyFilters.includes(filter.key)}
                      type="checkbox"
                      id={`plan-${filter.key}`}
                    />
                  </Col>
                  <Col>
                    <span className="filter-name">{filter.name}</span>
                  </Col>
                </Row>
              ))}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function PaymentInfo() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Add Payment
      </Button>

      <PaymentForm show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default PaymentInfo;
