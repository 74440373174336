import { useState, useEffect } from "react";
import { fetchCompanyReportFields } from "../database/firebaseApis";

export const useSwitches = (selectedCompany) => {
  const [switches, setSwitches] = useState({
    dailyReportPdf: false,
    weeklyReportPdf: false,
    monthlyReportPdf: false,
    faceBiometric: false,
  });

  useEffect(() => {
    if (selectedCompany) {
      fetchCompanyReportFieldsComp(selectedCompany);
    }
  }, [selectedCompany]);

  const fetchCompanyReportFieldsComp = async (companyId) => {
    try {
      const reportData = await fetchCompanyReportFields(companyId);
      const {
        dailyReportPdf,
        weeklyReportPdf,
        monthlyReportPdf,
        faceBiometric,
      } = reportData;
      setSwitches({
        dailyReportPdf,
        weeklyReportPdf,
        monthlyReportPdf,
        faceBiometric,
      });
    } catch (err) {
      console.log("Error Occurred", err);
    }
  };

  const handleToggleSwitch = (switchName) => {
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [switchName]: !prevSwitches[switchName],
    }));
  };

  return { switches, handleToggleSwitch };
};
