// const filteringLogic = (company, companyFilter, expirySpanState) => {
//   // If no filter is selected, always return true
//   if (companyFilter === "allFiltersApplied") {
//     return true;
//   }

import { expiryFilters } from "../database/expiryFilters";

//   const expiryDateTimestamp = company[companyFilter];

//   // Convert the Firestore Timestamp to a Date object
//   const expiryDate = expiryDateTimestamp
//     ? new Date(expiryDateTimestamp.seconds * 1000)
//     : null;

//   // If there's no expiry date, return false
//   if (!expiryDate) {
//     return false;
//   }

//   const today = new Date();

//   // Calculate the difference between the expiry date and today in days
//   const timeDifference = (expiryDate - today) / (1000 * 60 * 60 * 24); // Convert milliseconds to days

//   console.log(
//     `Expiry Date: ${expiryDate}, Today: ${today}, Days Difference: ${timeDifference}`
//   );

//   // Handle different expirySpanState values
//   if (expirySpanState === "0") {
//     // Return true if already expired
//     return timeDifference < 0; // Expired
//   }

//   if (expirySpanState === "30") {
//     return timeDifference >= 0 && timeDifference < 30; // Expiring in the next 30 days
//   }

//   if (expirySpanState === "45") {
//     return timeDifference >= 30 && timeDifference < 45; // Expiring between 30 and 45 days
//   }

//   if (expirySpanState === "60") {
//     return timeDifference >= 45 && timeDifference < 60; // Expiring between 45 and 60 days
//   }

//   // Default to false if nothing matches
//   return false;
// };

const filteringLogic = (company, companyFilter, expirySpanState) => {
  if (companyFilter === "allList") return true;
  const today = new Date();

  // Helper function to calculate time difference and check the expiry span
  const checkExpiry = (expiryDateTimestamp, expirySpanState) => {
    const expiryDate = expiryDateTimestamp
      ? new Date(expiryDateTimestamp.seconds * 1000)
      : null;

    if (!expiryDate) {
      return false; // If no expiry date, return false
    }

    // Calculate the difference between expiry date and today in days
    const timeDifference = (expiryDate - today) / (1000 * 60 * 60 * 24); // Convert milliseconds to days

    if (expirySpanState === "0") {
      return timeDifference < 0; // Already expired
    }
    if (expirySpanState === "30") {
      return timeDifference >= 0 && timeDifference < 30; // Expiring in the next 30 days
    }
    if (expirySpanState === "45") {
      return timeDifference >= 30 && timeDifference < 45; // Expiring between 30 and 45 days
    }
    if (expirySpanState === "60") {
      return timeDifference >= 45 && timeDifference < 60; // Expiring between 45 and 60 days
    }

    return false;
  };

  // Get all expiry filter keys (exclude "allFiltersApplied")
  const expiryFields = expiryFilters
    .filter((filter) => filter.key !== "allFiltersApplied")
    .map((filter) => filter.key);

  // If no specific filter is selected, check all expiry fields
  if (companyFilter === "allFiltersApplied" && expirySpanState) {
    return expiryFields.some((field) =>
      checkExpiry(company[field], expirySpanState)
    );
  }

  // If a specific filter is selected, apply the logic to that filter
  if (companyFilter !== "allFiltersApplied") {
    return checkExpiry(company[companyFilter], expirySpanState);
  }

  // Default to true if no filter or expiry span is applied
  return true;
};

export default filteringLogic;
