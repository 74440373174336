import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authSlice",

  initialState: {
    isAuthenticated: localStorage.getItem("isAuthenticated"),
    logedAdmin: JSON.parse(localStorage.getItem("adminUser")),

    myIP: null,
  },

  reducers: {
    setAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    setMyIP(state, action) {
      state.myIP = action.payload;
    },
  },
});

export const { setAuthenticated, setMyIP } = authSlice.actions;
export default authSlice.reducer;
