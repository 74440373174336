import emailjs from "emailjs-com";
const sendEmail = async (recipientEmail, span, filter) => {
  const templateParams = {
    to_email: recipientEmail,
    to_message: "Please Recharge",
    to_remaining: span,
    to_filter: filter,
  };

  try {
    await emailjs.send(
      "service_k3vm9ri",
      "template_fplon9v",
      templateParams,
      "LVJoN_lE4Fe7zY__Y"
    );
    console.log("Email sent successfully!");
  } catch (error) {
    console.error("Error sending email:", error);
  }
};

export default sendEmail;
