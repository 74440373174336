const { expiryFiltersWithUserCount } = require("./expiryFilters");
const { firestore } = require("./firebase");
const {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  where,
  query,
} = require("firebase/firestore");

const fetchActivityLogsFirebase = async () => {
  try {
    const companyCollectionRef = collection(firestore, "activityLogs");
    const querySnapshot = await getDocs(companyCollectionRef);
    const activityLogs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return activityLogs;
  } catch (error) {
    console.error("Error fetching company list:", error);
    throw error;
  }
};

const fetchCompanyList = async () => {
  try {
    const companyCollectionRef = collection(firestore, "Company");
    const querySnapshot = await getDocs(companyCollectionRef);
    const companies = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return companies;
  } catch (error) {
    console.error("Error fetching company list:", error);
    throw error;
  }
};

const fetchCompanyDetails = async (companyId) => {
  if (!companyId) return;
  try {
    // Reference to the company document

    const companyDocRef = doc(firestore, "Company", companyId);

    // Fetch the document snapshot
    const subcollectionRef = collection(companyDocRef, "FeaturesPayment");
    const subcollectionSnapshot = await getDocs(subcollectionRef);
    const subcollectionData = subcollectionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const companyDocSnapshot = await getDoc(companyDocRef);
    // console.log("subcollectionRef", subcollectionSnapshot);
    if (companyDocSnapshot.exists()) {
      // If the document exists, return its data
      return {
        ...companyDocSnapshot.data(),
        featuresPayment: subcollectionData, // Add subcollection data to the returned object
      };
    } else {
      // Throw an error if the company document doesn't exist
      throw new Error("Company not found");
    }
  } catch (error) {
    console.error("Error fetching company data:", error);
    throw error; // Rethrow the error to handle it in calling function
  }
};

const fetchUserList = async (companyId) => {
  try {
    console.log("companyid-----", companyId);
    const userCollectionRef = collection(
      firestore,
      "Company",
      companyId,
      "users"
    );
    const querySnapshot = await getDocs(userCollectionRef);
    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("users-----", querySnapshot);
    return users;
  } catch (error) {
    console.error("Error fetching user list:", error);
    throw error;
  }
};

const addLogActivityLogsFirebase = async (stateMent) => {
  try {
    // Fetch the list of companies to ensure companyId exists

    const companyCollectionRef = collection(firestore, "activityLogs");
    await addDoc(companyCollectionRef, stateMent);

    console.log("Activity Log Added Successfully");
  } catch (error) {
    console.error("Error adding Activity:", error);
    throw error;
  }
};

// Assume you have your Firebase config set up

const addPaymentFirebase = async (stateMent) => {
  const { companyName, expiryFilters, duration, numberOfUsers } =
    stateMent.message;

  try {
    // Fetch the company document using companyName
    const companyCollectionRef = collection(firestore, "Company");
    const q = query(
      companyCollectionRef,
      where("companyEmail", "==", companyName)
    );
    const companySnapshot = await getDocs(q);
    console.log("companySnamshot inside addPaymentfirebase", companySnapshot);
    if (companySnapshot.empty) {
      throw new Error(`No company found with name ${companyName}`);
    }

    // Assuming companyName is unique, use the first matching document
    const companyDoc = companySnapshot.docs[0];
    const companyDocRef = doc(firestore, "Company", companyDoc.id);

    // Prepare the updates for the filters expiry dates
    const updates = {};
    expiryFilters.forEach((filter) => {
      const newExpiryDate = calculateNewExpiryDate(duration); // Helper function to calculate new expiry date
      updates[expiryFiltersWithUserCount[filter].totalKey] = numberOfUsers;
      updates[filter] = newExpiryDate;
    });

    // Update the company document with the new expiry dates for the filters
    await updateDoc(companyDocRef, updates);
    console.log("Company filters updated successfully with new expiry dates");

    // Now add the payment record to the 'paymentsData' collection
    const paymentCollectionRef = collection(firestore, "paymentsData");
    await addDoc(paymentCollectionRef, stateMent);
    console.log("Payment record added successfully");
  } catch (error) {
    console.error("Error in addPaymentFirebase:", error);
    throw error;
  }
};

// Helper function to calculate the new expiry date based on the duration (30, 60, 90 days)
const calculateNewExpiryDate = (duration) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + parseInt(duration)); // Add the duration (in days) to the current date
  return currentDate;
};

const updateCompanyReportFields = async (companyId, fieldUpdates) => {
  try {
    // Fetch the list of companies to ensure companyId exists
    const companies = await fetchCompanyList();

    // Check if the provided companyId exists in the list of companies
    const companyExists = companies.some((company) => company.id === companyId);
    if (!companyExists) {
      throw new Error("Company not found");
    }

    // Construct the reference to the company's report document
    // const companyReportDocRef = doc(
    //   firestore,
    //   "Company",
    //   companyId,
    //   "Reports",
    //   "companyReport"
    // );
    const companyReportDocRefFace = doc(firestore, "Company", companyId);

    const { faceBiometric, dailyReportPdf, monthlyReportPdf, weeklyReportPdf } =
      fieldUpdates;
    console.log("what are field Updates", { fieldUpdates });
    // Update the fields in the company's report document

    // if (faceBiometric === undefined ) return;
    await updateDoc(companyReportDocRefFace, fieldUpdates);
    // await updateDoc(companyReportDocRef, rest);

    console.log("Company report fields updated successfully");
  } catch (error) {
    console.error("Error updating company report fields:", error);
    throw error;
  }
};
const fetchUserData = async (companyId, userId) => {
  try {
    const userDocRef = doc(firestore, "Company", companyId, "users", userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      return { id: userDocSnapshot.id, ...userDocSnapshot.data() };
    } else {
      throw new Error("User not found");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

const fetchCompanyReportFields = async (companyId) => {
  try {
    // Create a reference to the specific document in Firestore
    const companyReportDocRef = doc(
      firestore,
      "Company",
      companyId,
      "Reports",
      "companyReport"
    );
    const companyReportDocRefFace = doc(firestore, "Company", companyId);

    // Fetch the document snapshot
    const companyReportSnapshot = await getDoc(companyReportDocRef);
    const companyDataSnapshotFace = await getDoc(companyReportDocRefFace);

    // Check if the document exists
    if (companyReportSnapshot.exists() && companyDataSnapshotFace.exists()) {
      // Return the data from the document
      console.log("checking in if condition");
      const data = {
        id: companyReportSnapshot.id,
        ...companyReportSnapshot.data(),
      };
      const dataFace = {
        id: companyDataSnapshotFace.id,
        ...companyDataSnapshotFace.data(),
      };

      console.log("fetchCompanyReportFields----", {
        // ...data,
        ...dataFace,
      });

      return { ...dataFace };
    } else {
      // Handle the case where the document does not exist
      throw new Error(`Company report for companyId ${companyId} not found`);
    }
  } catch (error) {
    console.error(
      `Error fetching company report fields for companyId ${companyId}:`,
      error
    );
    throw error;
  }
};

const fetchAdminUsers = async () => {
  try {
    const adminUsersCollectionRef = collection(firestore, "Admin_Users");
    const querySnapshot = await getDocs(adminUsersCollectionRef);
    const adminUsers = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return adminUsers;
  } catch (error) {
    console.error("Error fetching admin user list:", error);
    throw error;
  }
};

const logActivity = async (activityData) => {
  try {
    const activityCollectionRef = collection(firestore, "ActivityLogs");
    await addDoc(activityCollectionRef, activityData);
    console.log("Activity logged successfully:", activityData);
  } catch (error) {
    console.error("Error logging activity:", error);
    throw error;
  }
};

module.exports = {
  fetchCompanyList,
  fetchUserList,
  fetchUserData,
  fetchAdminUsers,
  updateCompanyReportFields,
  fetchCompanyReportFields,
  fetchCompanyDetails,
  fetchActivityLogsFirebase,
  addLogActivityLogsFirebase,
  addPaymentFirebase,
  logActivity,
};
