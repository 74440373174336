import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AdminMayuresh from "./pages/AdminMayuresh";
import ActivityLogs from "./pages/ActivityLogs";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route index element={<Admin />} /> */}
          <Route index element={<AdminMayuresh />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/activityLogs" element={<ActivityLogs />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
