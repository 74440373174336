export const expiryFilters = [
  {
    key: "expireCalenderTaskPlan",
    name: "Calendar Task Plan",
  },
  {
    key: "expireCustomerCreationPlan",
    name: "Customer Creation Plan",
  },
  {
    key: "expireFaceAttendancePlan",
    name: "Face Attendance Plan",
  },
  {
    key: "expireFieldTrackingPlan",
    name: "Field Tracking Plan",
  },
  {
    key: "expireLeavePolicyPlan",
    name: "Leave Policy Plan",
  },
  {
    key: "expirePayrollPlan",
    name: "Payroll Plan",
  },
  {
    key: "expireReimbursementPlan",
    name: "Reimbursement Plan",
  },
  {
    key: "expireRouteOptimizationPlan",
    name: "Route Optimization Plan",
  },
  {
    key: "expireTaskOtpCompletionPlan",
    name: "Task OTP Completion Plan",
  },
  {
    key: "expireTimeSheetFillingPlan",
    name: "Time Sheet Feeling Plan",
  },
  {
    key: "allFiltersApplied",
    name: "All Filters Applied",
  },
  {
    key: "allList",
    name: "All List",
  },
];

export const expiryFiltersPayments = [
  {
    key: "expireCalenderTaskPlan",
    name: "Calendar Task Plan",
    totalKey: "totalCalendarTask",
  },
  {
    key: "expireCustomerCreationPlan",
    name: "Customer Creation Plan",
    totalKey: "totalCustomerCreation",
  },
  {
    key: "expireFaceAttendancePlan",
    name: "Face Attendance Plan",
    totalKey: "totalFaceAttendance",
  },
  {
    key: "expireFieldTrackingPlan",
    name: "Field Tracking Plan",
    totalKey: "totalFieldTracking",
  },
  {
    key: "expireLeavePolicyPlan",
    name: "Leave Policy Plan",
    totalKey: "totalLeavePolicy",
  },
  {
    key: "expirePayrollPlan",
    name: "Payroll Plan",
    totalKey: "totalPayroll",
  },
  {
    key: "expireReimbursementPlan",
    name: "Reimbursement Plan",
    totalKey: "totalReimbursement",
  },
  {
    key: "expireRouteOptimizationPlan",
    name: "Route Optimization Plan",
    totalKey: "totalRouteOptimization",
  },
  {
    key: "expireTaskOtpCompletionPlan",
    name: "Task OTP Completion Plan",
    totalKey: "totalTaskOtpCompletion",
  },
  {
    key: "expireTimeSheetFillingPlan",
    name: "Time Sheet Feeling Plan",
    totalKey: "totalTimeSheetFilling",
  },
];

export const expiryFiltersWithUserCount = {
  expireCalenderTaskPlan: {
    key: "expireCalenderTaskPlan",
    name: "Calendar Task Plan",
    totalKey: "totalCalendarTask",
  },
  expireCustomerCreationPlan: {
    key: "expireCustomerCreationPlan",
    name: "Customer Creation Plan",
    totalKey: "totalCustomerCreation",
  },
  expireFaceAttendancePlan: {
    key: "expireFaceAttendancePlan",
    name: "Face Attendance Plan",
    totalKey: "totalFaceAttendance",
  },
  expireFieldTrackingPlan: {
    key: "expireFieldTrackingPlan",
    name: "Field Tracking Plan",
    totalKey: "totalFieldTracking",
  },
  expireLeavePolicyPlan: {
    key: "expireLeavePolicyPlan",
    name: "Leave Policy Plan",
    totalKey: "totalLeavePolicy",
  },
  expirePayrollPlan: {
    key: "expirePayrollPlan",
    name: "Payroll Plan",
    totalKey: "totalPayroll",
  },
  expireReimbursementPlan: {
    key: "expireReimbursementPlan",
    name: "Reimbursement Plan",
    totalKey: "totalReimbursement",
  },
  expireRouteOptimizationPlan: {
    key: "expireRouteOptimizationPlan",
    name: "Route Optimization Plan",
    totalKey: "totalRouteOptimization",
  },
  expireTaskOtpCompletionPlan: {
    key: "expireTaskOtpCompletionPlan",
    name: "Task OTP Completion Plan",
    totalKey: "totalTaskOtpCompletion",
  },
  expireTimeSheetFillingPlan: {
    key: "expireTimeSheetFillingPlan",
    name: "Time Sheet Feeling Plan",
    totalKey: "totalTimeSheetFilling",
  },
};
